<template>
  <div>
    <v-container grid-list-lg>
      <v-layout row wrap>
        <v-flex
                xs12
                sm6
                md4
                v-for="ad of dashboard"
                :key="ad.id"
        >
          <v-card
                  class="mx-auto"
                  max-width="400"
          >
            <v-img
                    class="white--text align-end"
                    height="200px"
                    :src="ad.imageSrc"
            >
            </v-img>

            <v-card-text class="text--primary">
              <div>{{ad.description}}</div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                      color="primary"
                      text
                      :to="'/newbankpayment/'"
              >
                Dobít
              </v-btn>
            </v-card-actions>
          </v-card>

        </v-flex>
      </v-layout>
    </v-container>

  </div>

</template>

<script>
  export default {
    name: "UserHome",
    computed: {
      dashboard () {
        return this.$store.getters.dashboard
      }
    }
  }
</script>

<style scoped>
  .car-link {
    position: absolute;
    bottom: 50px;
    left: 50%;
    background: rgba(0, 0, 0, .5);
    transform: translate(-50%, 0);
    padding: 5px 15px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
</style>
