<template>
    <v-app>
        <v-navigation-drawer
                app
                temporary
                v-model="drawer"
        >
            <v-list>
                <v-list-item
                        v-for="link in links"
                        :key="link.title"
                        :to="link.url"
                >
                    <v-list-item-icon>
                        <v-icon v-if="link.icon" color="pink">{{ link.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title v-text="link.title"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-avatar>
                        <v-img :src="link.avatar"></v-img>
                    </v-list-item-avatar>
                </v-list-item>
                <v-list-item
                        v-if="isUserLoggedIn"
                        @click="onLogout"
                >
                    <v-list-item-icon>
                        <v-icon color="pink">mdi-exit-to-app</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title v-text="this.$t('toolbar.logout')"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

            </v-list>
        </v-navigation-drawer>

        <v-card
                color="grey lighten-4"
                flat
                height="100px"
                tile
        >
            <v-toolbar dense dark color="primary">
                <v-app-bar-nav-icon
                        @click="drawer = !drawer"
                        class="hidden-md-and-up"
                ></v-app-bar-nav-icon>

                <v-img
                        class="mx-2"
                        src="3ton_logo-white.png"
                        max-height="40"
                        max-width="80"
                        contain
                ></v-img>
                <v-spacer></v-spacer>
                <v-toolbar-title>
                    <router-link to="/" class="pointer">{{ $t('toolbar.title') }}</router-link>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items class="hidden-sm-and-down">
                    <v-btn
                            v-for="link in links"
                            :key="link.title"
                            :to="link.url"
                            text
                    >
                        <v-icon left>{{link.icon}}</v-icon>
                        {{link.title}}
                    </v-btn>
                    <v-btn
                            @click="onLogout"
                            text
                            v-if="isUserLoggedIn"
                    >
                        <v-icon left>mdi-exit-to-app</v-icon>
                        {{ $t('toolbar.logout') }}
                    </v-btn>

                </v-toolbar-items>
                <template>
                    <div class="d-flex justify-center align-center">
                        <v-menu
                                rounded="lg"
                                offset-y
                        >
                            <template v-slot:activator="{ attrs, on }">
                                <v-btn
                                        color="primary"
                                        class="white--text ma-1"
                                        v-bind="attrs"
                                        v-on="on"
                                        min-width="auto"
                                >
                                    <country-flag :country="getFlagUser" size="small"></country-flag>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item
                                        v-for="entry in getAllLanguages"
                                        :key="entry.title"
                                        @click="changeLocale(entry.language, entry.flag)"
                                        link
                                >
                                    <country-flag :country="entry.flag" :size="entry.size"></country-flag>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
            </v-toolbar>
        </v-card>

        <template v-if="error">
            <v-snackbar
                    :color="errorColor"
                    :multi-line="mode === 'multi-line'"
                    :timeout="5000"
                    @input="closeError"
                    :value="true"
            >
                {{ error }}
                <v-btn
                        dark
                        text
                        @click="closeError"
                >
                    Close
                </v-btn>
            </v-snackbar>
        </template>

        <v-main>
            <router-view></router-view>
        </v-main>

        <v-footer
                color="primary lighten-1"
                padless
        >
            <v-row
                    justify="center"
                    no-gutters
            >
                <v-col
                        class="primary lighten-2 py-4 text-center white--text"
                        cols="12"
                >
                    <v-container>{{ $t('foobar.title') }}
                        <v-spacer>Copyright © 2016-{{ new Date().getFullYear() }} — <strong>3ton s.r.o.</strong> All
                            rights reserved.
                        </v-spacer>
                        <a href="https://3ton.cz/dokumenty-ke-stazeni/">{{ $t('foobar.terms') }}</a>
                    </v-container>
                </v-col>
            </v-row>
        </v-footer>
    </v-app>
</template>

<script>

    export default {
        data() {
            return {
                drawer: false,
                mode: 'multi-line'
            }
        },
        created() {
        },
        computed: {
            error() {
                return this.$store.getters.error
            },
            errorColor() {
                return this.$store.getters.errorColor
            },
            isUserLoggedIn() {
                return this.$store.getters.isUserLoggedIn
            },
            getAllLanguages() {
                return this.$store.getters.getLanguages
            },
            getFlagUser() {
                return this.$store.getters.flag
            },
            links() {
                if (this.isUserLoggedIn) {
                    return [
                        {title: this.$t('toolbar.new-phone'), icon: 'mdi-note-plus-outline', url: '/new', avatar: ''},
                        {
                            title: this.$t('toolbar.my-numbers'),
                            icon: 'mdi-format-list-bulleted',
                            url: '/list',
                            avatar: ''
                        },
                        {
                            title: this.$t('toolbar.charging'),
                            icon: 'mdi-credit-card-outline',
                            url: '/newbankpayment',
                            avatar: ''
                        },
                    ]
                }
                return [{
                    title: this.$t('toolbar.login'),
                    icon: 'mdi-lock',
                    url: '/login',
                    avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRXNrK10hAHoSsCGscNDvoABRKLkIzOL6eLnyUqDTNCkWw8ZBxK&usqp=CAU'
                },
                    {title: this.$t('toolbar.register'), icon: 'mdi-face', url: '/registration', avatar: ''},
                    {
                        title: this.$t('toolbar.charging'),
                        icon: 'mdi-credit-card-outline',
                        url: '/newbankpayment',
                        avatar: ''
                    },
                ]
            },
        },
        methods: {
            closeError() {
                this.$store.dispatch("clearError")
            },
            onLogout() {
                this.$store.dispatch('logoutUser');
                this.$router.push('/')
            },
            changeLocale(locale, flag) {
                this.$store.dispatch('setFlag', flag);
                this.$store.dispatch('setLocale', locale);
            }
        }

    };
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>
