import store from '../store'
import Cookie from "js-cookie";

export default function (to, from, next) {
    if (store.getters.isAuthenticated && Cookie.get('AUTH-TOKEN') && Cookie.get('USER-ID') && Cookie.get('AUTH-TOKEN') !== "undefined") {
        next()
    } else {
        next('/login?loginError=true')
    }
}
