import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthGuard from './auth-guard'
import Home from '@/components/Home'
import Login from '@/components/Auth/Login'
import Registration from '@/components/Auth/Registration'
import NewBankPayment from '@/components/Payment/NewBankPayment'
import Phone from '@/components/Phone/Phone'
import PhoneList from '@/components/Phone/PhoneList'
import NewPhone from '@/components/Phone/NewPhone'

Vue.use(VueRouter)

  const routes = [
    {
      path: '/',
      name: 'home',
      title: 'My 3ton Kabinet',
      meta: {
        title: 'My 3ton Kabinet',
        metaTags: [
          {
            name: 'home',
            content: 'The home page 3ton.'
          },
          {
            property: 'og:home',
            content: 'The home page 3ton.'
          }
        ]
      },
      component: Home
    },
    {
      path: '/phone/:id',
      props: true,
      name: 'phone',
      component: Phone
    },
    {
      path: '/list',
      name: 'list',
      component: PhoneList,
      beforeEnter: AuthGuard
    },
    {
      path: '/new',
      name: 'newad',
      component: NewPhone,
      beforeEnter: AuthGuard
    },

    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/registration',
      name: 'registration',
      component: Registration
    },
    {
      path: '/newbankpayment',
      name: 'newbankpayment',
      component: NewBankPayment
    },

  ]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
