<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent ref="form" validation max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-layout row>
            <v-flex xs12 pl-5>
              <v-spacer></v-spacer>
              <v-btn
                  class="ma-2"
                  outlined
                  color="teal"
                  dark
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-calendar-month</v-icon>
                {{ $t('phone.phone-button-cdr') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('phone.phone-cdr-title') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                    prepend-icon="mdi-account-outline"
                    type="email"
                    label="Email*"
                    v-model="userEmail"
                    :rules="emailRules"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                    label="Typ(pdf,csv)*"
                    hide-detail
                    v-model="typeOfFile"
                    :items="['pdf','csv']"
                    style="max-width: 200px;"
                ></v-select>
              </v-col>

            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                    ref="menuFrom"
                    v-model="menuFrom"
                    :close-on-content-click="false"
                    :return-value="dateFrom"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dateFrom"
                        label="*From"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="dateFrom"
                      no-title
                      scrollable
                      color="teal lighten-1"
                      @input="$refs.menuFrom.save(dateFrom)"
                      :min=this.minDate
                      :max=this.maxDate
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuFrom = false">
                      {{ $t('buttons.cancel') }}
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menuFrom.save(dateFrom)">
                      {{ $t('buttons.ok') }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                    ref="menuTo"
                    v-model="menuTo"
                    :close-on-content-click="false"
                    :return-value="dateTo"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dateTo"
                        label="*To"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="dateTo"
                      no-title
                      scrollable
                      @input="$refs.menuTo.save(dateTo)"
                      :min=this.minDate
                      :max=this.maxDate
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuTo = false">
                      {{ $t('buttons.cancel') }}
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menuTo.save(dateTo)">
                      {{ $t('buttons.ok') }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
          <small>{{ this.smalLabel }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">{{ $t('buttons.close') }}</v-btn>
          <v-btn color="blue darken-1" text @click="onSave"
                 :loading="loading"
                 :disabled="!valid || loading"
          >{{ $t('buttons.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "CdrCalendar",
  props: ['phone'],
  data() {
    return {
      userEmail: '@',
      valid: false,
      dialog: false,
      dateFrom: new Date().toISOString().substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      minDate: new Date().toISOString().substr(0, 10),
      maxDate: new Date().toISOString().substr(0, 10),
      menuFrom: false,
      menuTo: false,
      smalLabel: '* indicates required field',
      typeOfFile: 'pdf',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ]
    }
  },
  computed: {
    loading() {
      return this.$store.getters.loading
    }
  },
  watch: {
    userEmail: 'validateField',
    //valid: 'validateField',
  },
  methods: {
    validateField() {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.valid = pattern.test(this.userEmail);
    },
    onSave() {
      this.$store.dispatch('getCdrFromServer', {
        number: this.phone.number,
        email: this.userEmail,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        typeOfFile: this.typeOfFile
      })
      this.dialog = false
    }
  },
  created() {
    const dateStart = new Date();
    dateStart.setMonth(dateStart.getMonth() - 3);

    this.userEmail = this.$store.getters.getUserEmail
    this.dateFrom = this.minDate = dateStart.toISOString().substr(0, 10);
    this.dateTo = this.maxDate = new Date().toISOString().substr(0, 10);
  }
}
</script>

<style scoped>

</style>
