<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6>
        <v-card class="elevation-12">
          <v-toolbar dark color="red darken-1">
            <v-toolbar-title>{{ $t('new-bank-payment.toolbar-title') }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="validPay" ref="form" validation>
              <v-row
                  class="fill-height"
                  align="center"
                  justify="center"
              >
                <div class="display-2">
                  {{ $t('new-bank-payment.warning-text') }}
                </div>
              </v-row>
              <v-row
                  class="fill-height"
                  align="center"
                  justify="center"
              >
                <div class="display-1">
                  {{ $t('new-bank-payment.vs-text') }}
                </div>
              </v-row>
              <v-spacer></v-spacer>
            </v-form>
          </v-card-text>

        </v-card>

      </v-flex>
    </v-layout>
  </v-container>

</template>

<script>
export default {
  name: "NewPayment",
  data() {
    return {
      validPay: false,
      loaderPay: true,
      alignment: 'center',
      justify: 'center',
      telnum: '',
      paymentsum: 200,
      telnumRules: [
        v => !!v || this.$t('new-bank-payment.rules-number-req'),
        v => (v && v.length === 9) || this.$t('new-phone.rules-number-valid'),
        v => (v && v >= 1) || this.$t('new-phone.rules-number-valid'),
      ],
      sumRules: [
        v => !!v || this.$t('new-bank-payment.rules-sum-req'),
        v => (v && v >= 200) || this.$t('new-bank-payment.rules-sum-valid'),
      ],

    }

  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        const payment = {
          telnum: this.telnum,
          paymentsum: this.paymentsum
        };

        this.validPay = false;
        this.loaderPay = false;
        this.resource.save({}, payment)
            .then(response => response.json())
            .then(urlredirect => window.location = (urlredirect.url))
      }
    },
  },
  created() {
    this.resource = this.$resource('pay/newbankpayment')
  }

}
</script>

<style scoped>

</style>
