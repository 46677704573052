<template>
    <div v-if="!loading">
        <v-container>
            <v-row dense>
                <v-flex xs12 sm6 offset-sm3>
                    <h1 class="text--secondary mb-3">{{ $t('phone-list.toolbar-title') }}</h1>

                    <v-card
                            class="elevation-10 mb-3"
                            v-for="phone in myPhones"
                            :key="phone.number"
                    >
                        <v-layout row>

                            <v-flex xs11>
                                <v-card-title class="headline font-weight-regular blue darken-3 white--text">
                                    {{ $t('phone-list.phone-title') }}{{phone.number}}
                                </v-card-title>
                                <v-card-text>
                                    <v-subheader class="pa-0">{{ $t('phone-list.phone-balance') }}</v-subheader>
                                    <h2 class="text--primary">{{phone.balance}}</h2>
                                    <v-spacer></v-spacer>
                                    <p></p>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            class="ma-0"
                                            outlined
                                            color="blue darken-3"
                                            :to="'/phone/' + phone.number"
                                    >{{ $t('phone-list.phone-button') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-flex>

                        </v-layout>
                    </v-card>
                </v-flex>
            </v-row>
        </v-container>
    </div>
    <div v-else>
        <v-container>
            <v-layout row>
                <v-flex xs12 class="text-xs-center pt-5">
                    <v-progress-circular
                            :size="100"
                            :width="4"
                            color="blue darken-3"
                            indeterminate
                    ></v-progress-circular>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: "PhoneList",
        computed: {
            myPhones() {
                return this.$store.getters.myPhones
            },
            loading() {
                return this.$store.getters.loading
            }
        },
        created() {
            this.$store.dispatch('fetchPhones')
        }
    }
</script>

<style scoped>
    .v-progress-circular {
        margin: 1rem;
    }
</style>
