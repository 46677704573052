<template>
    <v-container>
        <v-layout row>
            <v-flex xs12>
                <v-card
                        class="elevation-10 mb-3"
                >
                    <v-card-title class="headline font-weight-regular blue darken-3 white--text">{{
                        $t('phone.phone-title') }}{{phone.number}}
                    </v-card-title>
                    <v-card-text>
                        <v-divider class="mx-4"></v-divider>
                    </v-card-text>
                    <v-card-title>{{ $t('phone.phone-tarif') }}{{phone.tariff}}</v-card-title>
                    <v-card-text>
                        <v-divider class="mx-4"></v-divider>
                        <v-subheader class="pa-0">{{ $t('phone.phone-balance') }}{{phone.balance}}</v-subheader>
                        <p>{{ $t('phone.phone-limit') }}{{phone.creditLimit}}</p>
                        <p>{{ $t('phone.phone-status') }}{{phone.status}}</p>
                        <p>{{ $t('phone.phone-type') }}{{phone.typeOfSim}}</p>
                    </v-card-text>
                    <getCdrPhone :phone="phone"></getCdrPhone>
                 </v-card>

            </v-flex>
        </v-layout>
    </v-container>

</template>

<script>
    import CdrCalendar from "./CdrCalendar";

    export default {
        name: "PhonePhone",
        props: ['id'],
        computed: {
            phone() {
                const id = this.id;
                return this.$store.getters.adById(id)
            }
        },
        components: {
            getCdrPhone: CdrCalendar
        }
    }
</script>

<style scoped>

</style>
