<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md6>
                <v-card class="elevation-12">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>{{ $t('new-phone.toolbar-title') }}</v-toolbar-title>
                    </v-toolbar>

                    <v-card-text>
                        <v-form v-model="valid" ref="form" validation class="mb-3">
                            <v-text-field
                                    prepend-icon="mdi-account-outline"
                                    name="title"
                                    :label="this.$t('new-phone.label-phone')"
                                    type="text"
                                    v-model="number"
                                    :rules="numberRules"
                                    required
                            >
                            </v-text-field>
                            <v-text-field
                                    prepend-icon="mdi-lock-outline"
                                    name="PUK kod"
                                    :label="this.$t('new-phone.label-puk')"
                                    type="password"
                                    v-model="puk1"
                                    multi-line
                                    :rules="pukRules"
                                    required
                            >
                            </v-text-field>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-form>

                        <v-layout row>
                            <v-flex xs12 pl-5>
                                <v-spacer></v-spacer>
                                <v-btn
                                        :loading="loading"
                                        :disabled="!valid || loading"
                                        class="success"
                                        @click="createPhone">
                                    {{ $t('new-phone.button-add-phone') }}
                                </v-btn>
                            </v-flex>
                        </v-layout>

                    </v-card-text>

                </v-card>

            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "NewPhone",
        data() {
            return {
                valid: false,
                number: '',
                puk1: '',
                pukRules: [
                    v => !!v || this.$t('new-phone.rules-puk-req'),
                    v => (v && v.length >= 8) || this.$t('new-phone.rules-puk-eq'),
                    v => (v && v >= 1) || this.$t('new-phone.rules-puk-valid'),
                ],
                numberRules: [
                    v => !!v || this.$t('new-phone.rules-number-req'),
                    v => (v && v.length === 12) || this.$t('new-phone.rules-number-valid'),
                    v => (v && v >= 1) || this.$t('new-phone.rules-number-valid'),
                ]
            }
        },
        computed: {
            loading() {
                return this.$store.getters.loading
            }
        },
        methods: {
            createPhone() {
                if (this.$refs.form.validate()) {
                    const phone = {
                        number: this.number,
                        puk1: this.puk1
                    };
                    this.$store.dispatch('createPhone', phone)
                        .then(() => {
                                this.$router.push('/list')
                            }
                        ).catch(() => {
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>
