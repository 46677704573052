import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueResource from 'vue-resource'
import axios from 'axios';
import Cookie from "js-cookie";
import i18n from './i18n'
import countryFlag from 'vue-country-flag'

Vue.use(VueResource);


Vue.config.productionTip = true
Vue.http.options.root = 'https://payments.3ton.eu/'
Vue.http.options.emulateHTTP = true
Vue.http.headers.common['Access-Control-Allow-Origin'] = '*'
Vue.component('country-flag', countryFlag)

new Vue({
    router,
    store,
    vuetify,
    axios,
    countryFlag,
    i18n,

    created() {
        this.$store.dispatch('setLocale', i18n.locale)

        let quotes = this.$store.getters.getLanguages;
        quotes.forEach((item) => {
            if (item.language === i18n.locale)
                this.$store.dispatch('setFlag', item.flag)
        })

        if (Cookie.get('AUTH-TOKEN') && Cookie.get('USER-ID') && store.getters.isAuthenticated && Cookie.get('AUTH-TOKEN') !== "undefined") {
            this.$store.dispatch('autoLoginUser', Cookie.get('USER-ID'));
        }
    },

    render: h => h(App)
}).$mount('#app')
