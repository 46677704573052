import i18n from "../i18n";

export default {
    state: {
        loading: false,
        error: null,
        color: "error",
        loggedIn: false,
        locale: "cs",
        flag: "cz",
        allLanguages: [
            {
                flag: 'cz',
                language: 'cs',
                title: 'Česko',
                size: 'small'
            },
            {
                flag: 'us',
                language: 'en',
                title: 'English',
                size: 'small'
            },
            {
                flag: 'ru',
                language: 'ru',
                title: 'Русский',
                size: 'small'
            }
        ],
        userEmail: '',
        userAvatar: ''
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        setError(state, payload) {
            state.error = payload
        },
        clearError(state) {
            state.error = null
        },
        setErrorColor(state, payload) {
            state.color = payload
        },
        setLoggedIn(state, payload) {
            state.loggedIn = payload;
        },
        setLocale(state, payload) {
            i18n.locale = payload
            state.locale = payload
        },
        setFlag(state, payload) {
            state.flag = payload
        },
        setUserEmail(state, payload) {
            state.userEmail = payload
        },
        setUserAvatar(state, payload) {
            state.userAvatar = payload
        }

    },
    actions: {
        setLoading({commit}, payload) {
            commit('setLoading', payload)
        },
        setError({commit}, payload) {
            commit('setError', payload)
        },
        setErrorColor({commit}, payload) {
            commit('setErrorColor', payload)
        },
        clearError({commit}) {
            commit('clearError')
        },
        setLoggedIn({commit}, payload) {
            commit('setLoggedIn', payload)
        },
        setLocale({commit}, payload) {
            commit('setLocale', payload)
        },
        setFlag({commit}, payload) {
            commit('setFlag', payload)
        },
        setUserEmail({commit}, payload) {
            commit('userEmail', payload)
        },
        setUserAvatar({commit}, payload) {
            commit('userAvatar', payload)
        }

    }
    ,
    getters: {
        loading(state) {
            return state.loading
        },
        error(state) {
            return state.error
        },
        errorColor(state) {
            return state.color
        },
        loggedIn(state) {
            return state.loggedIn
        },
        locale(state) {
            return state.locale
        },
        flag(state) {
            return state.flag
        },
        getLanguages(state) {
            return state.allLanguages
        },
        getUserEmail(state) {
            return state.userEmail
        },
        getUserAvatar(state) {
            return state.userAvatar
        }
    }

}
