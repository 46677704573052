import axios from "axios";
import Cookie from "js-cookie";
import {config} from "./app-parametrs"
import i18n from '@/i18n'

class Phone {
    constructor(number, password) {
        this.number = number
        this.puk1 = password
    }
}

class CdrPhone {
    constructor(number, email = '', dateFrom, dateTo, typeOfFile) {
        this.authorization = ''
        this.operation = ''
        this.msisdn = number
        this.date_start = dateFrom
        this.date_end = dateTo
        this.format = typeOfFile
        this.email = email
    }
}

class ListPhone {
    constructor(number, balance, tariff, creditLimit, typeOfSim, partnerType, partnerName, status) {
        this.number = number
        this.balance = balance
        this.tariff = tariff
        this.creditLimit = creditLimit
        this.typeOfSim = typeOfSim
        this.partnerType = partnerType
        this.partnerName = partnerName
        this.status = status
    }
}

export default {
    state: {
        phones: [],
        dashboard: [
            {
                title: 'Dobíjení platební kartou',
                description: 'Dobíjení platební kartou',
                promo: true,
                imageSrc: 'https://www.svgrepo.com/show/13738/card.svg',
                id: '1'
            },
        ]
    },
    mutations: {
        createPhone(state, payload) {
            state.dashboard.push(payload)
        },
        loadPhones(state, payload) {
            state.phones = payload
        }
    },
    actions: {
        async createPhone({commit}, payload) {
            commit('clearError');
            commit('setLoading', true)
            try {
                const newPhone = new Phone(payload.number, payload.puk1);
                const authStr = 'Bearer '.concat(localStorage.getItem('user-token'));
                const resultPhones = []
                axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                await axios.post(config.url.API_PHONE_LINK + 'add', newPhone, {headers: {Authorization: authStr}})
                    .then((res) => {
                        if (res.status === 401) {
                            commit('setError', i18n.t('error.401'))
                            window.location = "/logout";
                        }
                        if (res.status === 403) {
                            commit('setErrorColor', 'error')
                            commit('setError', i18n.t('error.403'))
                        }
                        if (res.status !== 200) {
                            commit('setErrorColor', 'error')
                            commit('setError', res.statusText)
                        }
                        if (res.status === 200) {
                            commit('setErrorColor', 'success')
                            commit('setError', 'Do seznamu jste úspěšně přidali nové číslo.')
                            resultPhones.push(
                                new ListPhone(
                                    new ListPhone(
                                        newPhone.number.toString(),
                                        ",- Kč",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "")
                                )
                            )
                            commit("createPhone", resultPhones)
                        }
                    });
                commit('setLoading', false)
            } catch (error) {
                commit('setErrorColor', 'error')
                if (error.message.includes(403)) {
                    commit('setError', i18n.t('error.403'))
                } else if (error.message.includes(401)) {
                    console.log("ss0ss")
                    commit('setError', i18n.t('error.401'))
                } else {
                    commit('setError', error.message)
                }
                commit('setLoading', false)
                throw error
            }
        },
        async fetchPhones({commit}) {
            commit('clearError')
            commit('setLoading', true)

            const resultPhones = []
            if (Cookie.get('AUTH-TOKEN')) {
                const authStr = 'Bearer '.concat(Cookie.get('AUTH-TOKEN'));
                try {
                    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                    await axios.get(config.url.API_PHONE_LINK + 'all', {headers: {Authorization: authStr}})
                        .then((res) => {
                            if (res.status === 401) {
                                window.location = "/logout";
                            }
                            if (res.status === 200) {
                                Object.keys(res.data).forEach(key => {
                                    const phone = res.data[key]
                                    resultPhones.push(
                                        // number, balance, tariff, creditLimit, typeOfSim, partnerType, partnerName, status
                                        new ListPhone(
                                            phone.number.toString(),
                                            phone.balance.toString() + ",- Kč",
                                            phone.tariff,
                                            phone.creditLimit + ",- Kč",
                                            phone.typeOfSim,
                                            phone.partnerType,
                                            phone.partnerName,
                                            phone.status)
                                    )
                                })
                                commit('loadPhones', resultPhones)
                                commit('setLoading', false)
                            }

                        });
                } catch (error) {
                    commit('setErrorColor', 'error')
                    if (error.message.includes(403)) {
                        commit('setError', i18n.t('error.403'))
                    } else if (error.message.includes(400)) {
                        commit('setError', i18n.t('error.400'))
                    } else if (error.message.includes(401)) {
                        commit('setError', i18n.t('error.401'))
                        setTimeout(() => {  window.location = "/login?loginError=true" }, 2000);
                    } else {
                        commit('setError', error.message)
                    }
                    commit('setLoading', false)
                    throw error
                }
            }

        },
        async getCdrFromServer({commit}, payload) {
            commit('clearError');
            commit('setLoading', true)
            try {
                const newCdr = new CdrPhone(payload.number, payload.email, payload.dateFrom, payload.dateTo, payload.typeOfFile);
                const authStr = 'Bearer '.concat(Cookie.get('AUTH-TOKEN'));
                await axios.post(config.url.API_REPORT_LINK + 'cdr', newCdr, {headers: {Authorization: authStr}})
                    .then((res) => {
                        if (res.status === 401) {
                            commit('setError', i18n.t('error.401'))
                        }
                        if (res.status === 403) {
                            commit('setErrorColor', 'error')
                            commit('setError', i18n.t('error.403'))
                        }
                        if (res.status !== 200) {
                            commit('setErrorColor', 'error')
                            commit('setError', res.statusText)
                        }
                        if (res.status === 200) {
                            commit('setErrorColor', 'success')
                            commit('setError', i18n.t('cdr.success-message'))
                        }
                    });
                commit('setLoading', false)
            } catch (error) {
                commit('setErrorColor', 'error')
                if (error.message.includes(403)) {
                    commit('setError', i18n.t('error.403'))
                } else if (error.message.includes(401)) {
                    commit('setError', i18n.t('error.401'))
                } else {
                    commit('setError', error.message)
                }
                commit('setLoading', false)
                throw error
            }
        }
    },
    getters: {
        phones(state) {
            return state.phones
        },
        myPhones(state) {
            return state.phones
        },
        adById(state) {
            return adId => {
                return state.phones.find(listPhone => listPhone.number === adId)
            }
        }
    }
}
