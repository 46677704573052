class User {
    constructor(id) {
        this.id = id
    }
}

import axios from 'axios';
import Cookie from "js-cookie";
import {config} from "./app-parametrs"

export default {
    state: {
        user: null,
        status: '',
        token: Cookie.get('AUTH-TOKEN') || '',
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setStatus(state, payload) {
            state.status = payload;
        },
        setToken(state, payload) {
            state.token = payload
        },
    },
    actions: {
        registerUser({commit}) {

            commit('clearError');
            commit('setLoading', true);
            try {
                window.location = (config.url.API_LOGIN_UI_LINK + 'signup')
            } catch (error) {
                commit('setLoading', false);
                commit('setError', error.message);
                throw error
            }
        },
        loginUser({commit}) {
            commit('setToken', null)
            commit('clearError')
            commit('setLoading', true)
            try {
                window.location = (config.url.API_LOGIN_UI_LINK + 'login')
            } catch (error) {
                commit('setError', error.message);
                if (error.response.data.message) {
                    commit('setError', error.response.data.message);
                }
                localStorage.removeItem('user-token');
                commit('setToken', null);
                commit('setStatus', 'error');
                commit('setLoading', false);
                commit('setErrorColor', 'error');
                throw error
            }
        },
        autoLoginUser({commit}, payload) {
            commit('setUser', new User(payload))
        },
        async logoutUser({commit}) {
            const token = 'Bearer '.concat(localStorage.getItem('user-token'));
            localStorage.removeItem('user-token');
            Cookie.set('AUTH-TOKEN', "")
            Cookie.set('USER-ID', "")
            commit('setToken', null);
            commit('setStatus', 'error');
            commit('setUser', null)
            try {
                await axios.get(config.url.API_AUTH_LINK + 'user/logout', {headers: {Authorization: token}})
                    .then(response => {
                        if (response.status === 200) {
                            commit('setError', "Success logout");
                            commit('setLoading', false);
                            commit('setErrorColor', "success");
                            window.location = (config.url.API_LOGIN_UI_LINK + 'logout')
                        }
                    })
                    .catch(function (error) {
                        if (error.message) {
                            commit('setError', error.message);
                            commit('setErrorColor', 'error');
                        }
                    })
            } catch (error) {
                commit('setError', error.message);
                if (error.response.data.message) {
                    commit('setError', error.response.data.message);
                }
                commit('setErrorColor', "error");
                throw error
            }

        },
        async validateToken({commit}, payload) {
            try {
                const token = payload
                payload = 'Bearer '.concat(payload);
                await axios.get(config.url.API_AUTH_LINK + 'user/me', {headers: {Authorization: payload}})
                    .then(user => {
                        localStorage.setItem('user-token', token);
                        Cookie.set('AUTH-TOKEN', token)
                        Cookie.set('USER-ID', user.data.id)
                        commit('setToken', token);
                        commit('setStatus', 'success');
                        commit('setUser', new User(user.data.id))
                        commit('setUserEmail', user.data.email)
                        commit('setUserAvatar', user.data.imageUrl)
                        commit('setLoading', false);
                        commit('setErrorColor', "success");
                    })
                    .catch(function (error) {
                        if (error.message) {
                            commit('setError', error.message);
                            commit('setErrorColor', 'error');
                        }
                        if (error.response && error.response.status === 401) {
                            window.location = (config.url.API_LOGIN_UI_LINK + 'logout')
                        }
                    })

            } catch (error) {
                commit('setError', error.message);
                commit('setErrorColor', 'error');
                throw error
            }

        },
    },
    getters: {
        user(state) {
            return state.user
        },
        isUserLoggedIn(state) {
            return state.user !== null
        },
        isAuthenticated(state) {
            return state.token !== null
        },
        authStatus(state) {
            return state.status !== null
        },
    },
}
