const prod = {
    url: {
        // phone servis api
        API_PHONE_LINK: 'https://micro.3ton.eu/api/phone/',
        // report servis api
        API_REPORT_LINK: 'https://micro.3ton.eu/api/report/',
        // login ui page
        API_LOGIN_UI_LINK: 'https://login.3ton.eu/',
        // auth server
        API_AUTH_LINK: 'https://auth.3ton.eu/'
    }
};
const dev = {
    url: {
        // phone servis api
        API_PHONE_LINK: 'http://localhost:9004/api/phone/',
        // report servis api
        API_REPORT_LINK: 'http://localhost:9004/api/report/',
        // login ui page
        API_LOGIN_UI_LINK: 'http://localhost:3000/',
        // auth server
        API_AUTH_LINK: 'http://localhost:9001/'
    }
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
