import Vue from 'vue'
import Vuex from 'vuex'
import Dashboard from './phone'
import User from './user'
import Shared from './shared'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        Dashboard,
        User,
        Shared,
    }
});
