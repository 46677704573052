<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md6>
                <v-card class="elevation-12">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Registration Form</v-toolbar-title>
                    </v-toolbar>
                </v-card>

            </v-flex>
        </v-layout>
    </v-container>

</template>

<script>
    export default {
        name: "AuthRegistration",
        data() {
            return {}
        },
        computed: {},
        methods: {},
        created() {
                    this.$store.dispatch('registerUser');
        }

    }
</script>

<style scoped>

</style>
