<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md6>
            </v-flex>
        </v-layout>
    </v-container>

</template>

<script>
    import Cookie from "js-cookie";

    export default {
        name: "AuthLogin",
        data() {
            return {}
        },
        computed: {},
        methods: {},
        created() {
            if (this.$route.query['loginError']) {
                this.$store.dispatch('setError', 'Please log in to access this page.')
                this.$store.dispatch('loginUser');
            }

            if (Cookie.get('AUTH-TOKEN') || this.$route.query['accessToken'] !== "") {
                let token = ""
                if (!Cookie.get('AUTH-TOKEN')) {
                    token = this.$route.query['accessToken'];
                    localStorage.setItem('user-token', this.$route.query['accessToken'])
                    Cookie.set('AUTH-TOKEN', this.$route.query['accessToken'])
                } else {
                    if (this.$route.query['accessToken'] && Cookie.get('AUTH-TOKEN') !== this.$route.query['accessToken']) {
                        token = this.$route.query['accessToken'];
                    } else {
                        token = Cookie.get('AUTH-TOKEN');
                    }
                }
                this.$store.dispatch('validateToken', token)
            }

        }
    }
</script>

<style scoped>

</style>
